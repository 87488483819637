<template>
    <div class="table-page">
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>石化专属</el-breadcrumb-item>
            <el-breadcrumb-item>预约记录</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: center" class="hidden-sm-and-down">
            <el-form :model="search" :inline="true">
                <el-form-item label="状态">
                    <el-select v-model="search.state" placeholder="选择状态" style="width: 100px" size="small" clearable>
                        <el-option label="已预约" value="0"></el-option>
                        <el-option label="已取消" value="-1"></el-option>
                        <!-- <el-option label="已到院" value="1"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="院区">
                    <el-select v-model="search.hospital" placeholder="请选择" style="width: 150px" size="small" clearable
                        @change="selectHospital">
                        <el-option :label="item.name" :value="item.name" v-for="item in hospitalList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="就诊日期">
                    <el-date-picker style="width: 140px" v-model="search.date" type="date" align="right" unlink-panels
                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" size="small">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.name" size="small" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item label="身份证">
                    <el-input v-model="search.idnum" size="small" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
                    <!--                        <el-button type="default" @click="doExport">导出 EXCEL</el-button>-->
                </el-form-item>
            </el-form>
        </div>
        <div class="page-main">
            <el-table :data="table.data" style="width: 100%" height="100%" :row-class-name="tableRowClassName"
                v-loading="loading" border>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;">
                            <div>患者姓名：{{ props.row.name }}</div>
                            <div>身份证号：{{ props.row.idnum }}</div>
                            <div>联系电话：{{ props.row.phone }}</div>
                            <div>病情简述：{{ props.row.package }}</div>
                            <div>病情简述：<span v-html="props.row.items.join(',')"></span></div>
                            <div>病情简述：{{ props.row.desc }}</div>
                            <div>订单时间：{{ props.row.created }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
                    :width="v.width" :formatter="v.formatter" :key="idx">
                </el-table-column>
                <!-- <el-table-column align="center" label="操作" width="110">
                    <template slot-scope="scope">
                        <el-dropdown size="small">
                            <el-button type="default" size="small">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="setState(scope.row, 0)">设置状态：已预约</el-dropdown-item>
                                <el-dropdown-item @click.native="setState(scope.row, 3)">设置状态：已沟通</el-dropdown-item>
                                <el-dropdown-item @click.native="setState(scope.row, 1)">设置状态：已到院</el-dropdown-item>
                                <el-dropdown-item @click.native="setState(scope.row, 2)">设置状态：已完成</el-dropdown-item>
                                <el-dropdown-item divided></el-dropdown-item>
                                <el-dropdown-item @click.native="handleUpdate(scope.row)">修改</el-dropdown-item>
                                <el-dropdown-item @click.native="handleDel(scope)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]" :total="table.total"
                :page-size="table.pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
    </div>
</template>

<script>
import formatter from "@/data/formatter"

export default {
    data() {
        // let now = new Date()
        // let today = now.format('yyyy-MM-dd')
        return {
            loading: false,
            search: { hospital: '', doctor: '', date: '', keywords: '' },
            hospitalList: [],
            doctorList: [],
            table: {
                columns: [
                    { title: '院区', field: 'hospital', width: '' },
                    { title: '就诊日期', field: 'date', width: '' },
                    { title: '就诊时间', field: 'time', width: '' },
                    { title: '患者姓名', field: 'name', width: '' },
                    { title: '套餐名称', field: 'package', width: '' },
                    { title: '健康管家', field: 'doctor', width: '' },
                    { title: '预约状态', field: 'status', width: '', formatter: this.statusFormatter },
                    { title: '金额', field: 'price', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 10
            },
        }
    },
    methods: {
        statusFormatter(row, column) {
            const e = row[column.property]
            switch (e) {
                case 1:
                    return "已到院"
                case 0:
                    return "已预约"
                case -1:
                    return "用户取消"
                default:
                    return e
            }
        },
        tableRowClassName({ row }) {
            if (row.state === -1) {
                return 'warning-row';
            } else if (row.state > 0) {
                return 'success-row';
            }
            return '';
        },
        selectHospital() {
            this.search.doctor = ''
            this.getDoctor()
        },
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        handleUpdate(row) {
            this.form = row
            this.$router.push(`/main/shihua/form?id=${row.id}`)
        },
        async setState(row, state) {
            row.state = state
            const resp = await this.$http.put(`/enterprise/shihua/appoint/${row.id}`, row)
            if (resp.data.code === 200) {
                this.$message.success("设置成功")
                this.getData()
            } else {
                this.$message.error("设置失败")
            }
        },
        async handleDel(scope) {
            const confirm = await this.$confirm(`确认删除 ${scope.row.patient} 的记录吗？`)
            if (!confirm) return
            const resp = await this.$http.delete(`/enterprise/shihua/appoint/${scope.row.id}`)
            if (resp.data.code === 200) {
                this.$message.success("删除成功")
                this.getData()
            } else {
                this.$message.error("删除失败")
            }
        },
        async getHospital() {
            const hl = await this.$http.get('/mingde/hospital/')
            this.hospitalList = hl.data.data
        },
        async getDoctor() {
            const hl = await this.$http.get('/mingde/shihua/doctors', { params: this.search })
            this.doctorList = hl.data.data
        },
        async getData() {
            let params = this.search
            params['page'] = this.table.page
            params['size'] = this.table.pageSize
            const resp = await this.$http.get('/enterprise/shihua/appoint/records', { params: params })
            console.log(111, resp.data)
            this.table.data = resp.data.records || []
            this.table.total = resp.data.total
        },
        handleSearch() {
            this.getData()
        },
    },
    mounted() {
        this.getHospital()
        this.getData()
    }
}
</script>

<style>
.warning-row {
    background-color: #ffc0be !important;
}

.success-row {
    background-color: #f0f9eb !important;
}
</style>
